import React, { Component } from "react";
import JuliaFerencziImg from "../style/images/face_1.jpg"
 
class About extends Component {
  render() {
    return (
        <>
        <section className="background--blue-darker">
            <div className="container">
                <div className="head">
                    <h1>About Julia</h1>
                    <p className="color--grey">I'm Julia, full-stack developer based in London, 
                    specialising in building exceptional, bespoke websites using simple html/css or Wordpress, 
                    as well as complex web apps with custom logic with React/NodeJS or Java.</p>
                </div>
            </div>
        </section> 
        <section >
            <div className="container">
                <div className="about-content">
                    <div className="about-description col-8">
                        {/* <img src={JuliaFerencziImg} /> */}
                        <p>I am a London-based web developer with more than 15 years of experience
                            working on websites and apps, including from designer experiment to database desgin as well. 
                            My background in IT and development covers a range of sectors, from bank acros goverment work to e-commerce.</p>
                        <p>The ability to combine technical knowledge with creativity is what enticed me to this field. 
                            My passion for design and the IT began early, this is my way to combine the passion with the knowledge.</p>
                        <p>
                        Growing up and educating in Hungary, I started my IT work in a biggest bank of Hungary GE Money Bank.
                        I successfully worked for Intesa Sanp
                        aolo CIB Bank and OTP Bank and MKB Bank as well.
                        I worked several times in govermentprojects as consultan and I worked years as lead developer
                        in IT company of Hungarian Government.
                        I was lucky to work for T-Mobile in Budapest also, one of Europe’s largest telecommunication companies.
                        </p>
                        <p>
                        I moved to London in 2018 and began work with an international company in central of the city called LexisNexis. 
                        New place as new chalanges. As the Java developer on my company, I improved in C# very fast and started learn several new technologies
                        as React and NodeJS as well. I am working as full-stack developer, with React and C# at the moment.
                        </p>
                        <p>I'm a true polyglot developer, having lead/architectural experience with a wide variety of tech stacks,
                            from Java, C#, Oracle, JBoss, IBM servers, Node on backend, to React and html/js/css on the frontend.
                        </p>
                    </div>
                    <div className="about-timeline col-4">
                        <h2>Skill summary</h2>
                        <h4>Front-end / Back-end development</h4>
                        <ul>
                            <li>15+ years experience</li>
                            <li>Involved in over 30 projects</li>
                            <li>Team lead experience</li>
                            <li>Architectural/design experience</li>
                        </ul>
                        <h2>Technologies</h2>
                        <ul>
                            <li>Java, C#</li>
                            <li>React / NodeJS</li>
                            <li>CSS, HTML5, JavaScript & SASS</li>                            
                            <li>SQL / Oracle, PostgreSql, DB2</li>
                        </ul>
                        <h2>Projects</h2>
                        <h4>Latest - LexisNexis</h4>
                        <ul>                            
                            <li>Tolley Library / Guidance</li>
                            <li>Legal feed</li>
                        </ul>
                        <h4>Bank</h4>
                        <ul>
                            <li>FX transfer control</li>
                            <li>Corporate customer account opening</li>
                            <li>Automatic credit disbursement</li>
                            <li>Commission credit</li>
                            <li>Document and Task Management tool</li>
                            <li>Cash Management and Monitoring</li>
                            <li>Legal customer control system</li>
                            <li>Customer complaint handling</li>
                            <li>Mastercard MDES Customer System integration</li>
                            <li>Backend File Manager System for DataWareHouse</li>
                        </ul>
                        <h4>Government</h4>
                        <ul>
                            <li>Face recognation system integration</li>
                            <li>Postal return magagment system</li>
                            <li>Vehicle and personal information service</li>
                            <li>People information and address registry system</li>
                            <li>Addiction patients registry system</li>
                            <li>Mortality registry system</li>
                        </ul>
                        <h4>Others</h4>
                        <ul>
                            <li>E-learning system for University of Pecs</li>
                            <li>Airline Staff and Service Arrangement Portal</li>
                            <li>Education Data Exchange Centre</li>
                            <li>Compliance and customer registry for hungarian gambling company</li>
                            <li>T-Mobile - Customer address data integraion project</li>
                            <li>T-Mobile - Customer registry system</li>
                            <li>Thermogenicglass.co.uk</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section> 
    </>   
    );
  }
}
 
export default About;
