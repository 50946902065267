
import React, { useState } from "react";
 
function Contact() {

    let [name, setName, ] = useState();
    let [email, setEmail] = useState();
    let [message, setMessage] = useState();

    const cleareFields = () => {        
        setEmail("");
        setName("");
        setMessage("");
    }

    const handleSend = () => {
        console.log("ok");
        const data = { email: 'juliaferenczi@gmail.com', name: "Julia", message: "mess" };
        fetch("http://juliaferenczi.hu/sendMail", {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
    }

    return (
        <>
        <section className="background--blue-darker">
            <div className="container">
                <div className="head">
                    <h1>Contact</h1>
                    <p className="color--grey">If you are interested in working together or wish to find out more about 
                    what I can help with, use one of the below methods to get in touch with me.</p>
                </div>
            </div>
        </section> 
        <section >
            <div className="container">
                <div className="contact-content">
                    <div className="contact-left col-6 col-s-12">
                        <h2>Send me a message</h2>
                        <div className="floating-label">                            
                            <input type="text" name="name" id="name" placeholder="Name*" maxLength="20" 
                                value={name} onChange={event => setName(event.target.value)} />
                            <label>Name*</label>
                        </div>
                        <div className="floating-label">                            
                            <input type="text" name="email" id="email" placeholder="Email*" autocomplete="off" 
                                value={email} maxLength="60" onChange={event => setEmail(event.target.value)} />
                            <label>Email*</label>
                        </div>
                        <div className="floating-label">                            
                            <textarea name="message" id="message" placeholder="Short message*" autocomplete="off" maxLength="180"
                                value={message} onChange={event => setMessage(event.target.value)} />
                            <label>Short message*</label>
                        </div>
                        <div>
                            <button className="button-clear" onClick={cleareFields}>Clear</button>
                            <button className="button-send" onClick={handleSend}>Send enquiry</button>
                        </div>
                    </div>
                    <div  className="contact-right col-6 col-s-12">
                        <h2>Contact details</h2>
                        <div>
                            <ul>
                                <li><a href="tel:+447957493632"><i className="fa fa-mobile"></i>+44 795749 3632</a></li>
                                <li><a href="mailto:jferenczi@yahoo.com"><i className="fa fa-at"></i>jferenczi 'at' yahoo.com</a></li>
                                <li><a href="https://twitter.com/ferenczi_julia" target="_blank"><i className="fa fa-twitter"></i>Twitter</a></li>
                                <li><a href="https://www.linkedin.com/in/juliaferenczi/" target="_blank"><i className="fa fa-linkedin"></i>Linkedin</a></li>
                            </ul>
                        </div>
                    </div>                    
                </div>
            </div>
        </section> 
    </>   
    );
}
 
export default Contact;
