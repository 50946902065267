import React, { Component } from "react";
import {
    NavLink
  } from "react-router-dom";
 
class Nav extends Component {
  render() {
    return (
    <div className="container">
        <header><NavLink exact to="/"><span>Julia Ferenczi</span></NavLink></header>
        <ul className="menu">
          {/* <li><NavLink to="/services">Services</NavLink></li> */}
          <li><NavLink to="/about">About</NavLink></li>
          <li><NavLink to="/contact">Contact</NavLink></li>
          {/* <li><NavLink to="/demo">Demo</NavLink></li> */}
        </ul>
        <ul className="menu-social">
          <li><a href="https://twitter.com/ferenczi_julia" target="_blank"><i className="fa fa-twitter"></i></a></li>
          <li><a href="https://www.linkedin.com/in/juliaferenczi/" target="_blank"><i className="fa fa-linkedin"></i></a></li>
        </ul>
      </div>
    );
  }
}
 
export default Nav;